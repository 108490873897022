<template>
  <BaseDialog :title="modalTitle" :btn1Loading="loading" @close="$emit('close')" @confirm="onConfirm">
    <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <el-form-item label="付款方式種類" prop="paymentType">
        <el-select v-if="!disabledField" v-model="formData.paymentType" :disabled="!!paymentId" @change="onPaymentTypeChange">
          <el-option
            v-for="type in displayPaymentTypeOptions"
            :key="type.value"
            :label="type.label"
            :value="type.value"
          />
        </el-select>
        <p v-if="disabledField">{{ get(orderPaymentTypeConfig, `${formData.paymentType}.label`) }}</p>
      </el-form-item>

      <el-form-item v-if="formData.paymentType === 'sinoPacEpos'" label="限定購買商品類型" prop="limitProductType">
        <el-select v-if="!disabledField" v-model="formData.limitProductType" :disabled="!!paymentId">
          <el-option
            v-for="type in displayPaymentLimitProductTypeOptions"
            :key="type.value"
            :label="type.label"
            :value="type.value"
          />
        </el-select>
        <p v-if="disabledField">{{ get(paymentlimitProductTypeConfig, `${formData.limitProductType}.label`) }}</p>
      </el-form-item>

      <el-form-item label="付款方式名稱" prop="paymentName">
        <el-input v-model="formData.paymentName" placeholder="請輸入" />
      </el-form-item>
      <el-form-item label="啟用付款方式" prop="enabled">
        <el-switch
          v-model="formData.enabled"
          inactive-text="關閉"
          active-text="開啟"
        />
      </el-form-item>
      <!-- 永豐 -->
      <div v-if="formData.paymentType === 'sinoPacEpos'">
        <el-form-item label="特店代號（MID）" prop="mid">
          <el-input v-if="!disabledField" v-model="formData.mid" placeholder="請輸入" />
          <p v-if="disabledField">{{ formData.mid }}</p>
        </el-form-item>
        <el-form-item label="終末機代號（TID）" prop="tid">
          <el-input v-if="!disabledField" v-model="formData.tid" placeholder="請輸入" />
          <p v-if="disabledField">{{ formData.tid }}</p>
        </el-form-item>
        <el-form-item label="交易安全碼（SID）" prop="sid">
          <el-input v-if="!disabledField" v-model="formData.sid" placeholder="請輸入" />
          <p v-if="disabledField">{{ formData.sid }}</p>
        </el-form-item>
        <el-form-item label="開啟價金保管" prop="promise">
          <el-switch
            v-if="!disabledField"
            v-model="formData.promise"
            inactive-text="關閉"
            active-text="開啟"
          />
          <p v-if="disabledField">{{ formData.promise ? '開啟' : '關閉' }}</p>
        </el-form-item>
      </div>

      <!-- 藍新 -->
      <div v-if="formData.paymentType === 'newebpay'">
        <el-form-item label="MerchantId" prop="newebpayMerchantId">
          <p v-if="disabledField">{{ formData.newebpayMerchantId }}</p>
          <el-input
            v-if="!disabledField"
            v-model="formData.newebpayMerchantId"
            placeholder="請輸入"
          />
        </el-form-item>
        <el-form-item label="HashKey" prop="newebpayHashKey">
          <p v-if="disabledField">{{ formData.newebpayHashKey }}</p>
          <el-input
            v-if="!disabledField"
            v-model="formData.newebpayHashKey"
            placeholder="請輸入"
          />
        </el-form-item>
        <el-form-item label="HashIV" prop="newebpayHashIV">
          <p v-if="disabledField">{{ formData.newebpayHashIV }}</p>
          <el-input
            v-if="!disabledField"
            v-model="formData.newebpayHashIV"
            placeholder="請輸入"
          />
        </el-form-item>
      </div>

      <!-- LINE Pay -->
      <div v-if="formData.paymentType === 'linepay'">
        <el-form-item label="Channel ID" prop="linePayChannelId">
          <p v-if="disabledField">{{ formData.linePayChannelId }}</p>
          <el-input
            v-if="!disabledField"
            v-model="formData.linePayChannelId"
            placeholder="請輸入"
          />
        </el-form-item>
        <el-form-item label="Channel Secret Key" prop="linePayChannelSecret">
          <p v-if="disabledField">{{ formData.linePayChannelSecret }}</p>
          <el-input
            v-if="!disabledField"
            v-model="formData.linePayChannelSecret"
            placeholder="請輸入"
          />
        </el-form-item>
      </div>

      <!-- 支付連 -->
      <div v-if="formData.paymentType === 'pchomepay'">
        <el-form-item label="APP ID" prop="pcHomePayAppID">
          <p v-if="disabledField">{{ formData.pcHomePayAppID }}</p>
          <el-input
            v-if="!disabledField"
            v-model="formData.pcHomePayAppID"
            placeholder="請輸入"
          />
        </el-form-item>
        <el-form-item label="Secret" prop="pcHomePaySecret">
          <p v-if="disabledField">{{ formData.pcHomePaySecret }}</p>
          <el-input
            v-if="!disabledField"
            v-model="formData.pcHomePaySecret"
            placeholder="請輸入"
          />
        </el-form-item>
      </div>

      <!-- 街口 -->
      <div v-if="formData.paymentType === 'jkos'">
        <el-form-item label="Api-Key" prop="jkosApiKey">
          <p v-if="disabledField">{{ formData.jkosApiKey }}</p>
          <el-input
            v-if="!disabledField"
            v-model="formData.jkosApiKey"
            placeholder="請輸入"
          />
        </el-form-item>
        <el-form-item label="Sercert-Key" prop="jkosSercertKey">
          <p v-if="disabledField">{{ formData.jkosSercertKey }}</p>
          <el-input
            v-if="!disabledField"
            v-model="formData.jkosSercertKey"
            placeholder="請輸入"
          />
        </el-form-item>
        <el-form-item label="Store-ID" prop="jkosStoreId">
          <p v-if="disabledField">{{ formData.jkosStoreId }}</p>
          <el-input
            v-if="!disabledField"
            v-model="formData.jkosStoreId"
            placeholder="請輸入"
          />
        </el-form-item>
      </div>

      <div v-if="formData.promise">
        <el-form-item label="價金保管期限(天)" prop="promisePeriodDays">
          <p v-if="disabledField">{{ formData.promisePeriodDays }}</p>
          <!-- <el-input-number
            v-if="!disabledField"
            v-model.number="formData.promisePeriodDays"
            :min="365"
            :max="540"
            label="價金保管期限(天)"
          /> -->
          <el-input
            v-if="!disabledField"
            v-model="formData.promisePeriodDays"
          />
        </el-form-item>
        <el-form-item label="履約保證種類" prop="promiseVendor">
          <p v-if="disabledField">{{ paymentPromiseVendorConfig[formData.promiseVendor].label }}</p>
          <el-select v-if="!disabledField" v-model="formData.promiseVendor" :disabled="!!paymentId">
            <el-option
              v-for="vendor in paymentPromiseVendorConfig"
              :key="vendor.value"
              :label="vendor.label"
              :value="vendor.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Channel ID" prop="channelId">
          <el-input v-if="!disabledField" v-model="formData.channelId" placeholder="請輸入" />
          <p v-if="disabledField">{{ formData.channelId }}</p>
        </el-form-item>
      </div>
    </el-form>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import {
  orderPaymentTypeConfig,
  paymentPromiseVendorConfig,
  paymentlimitProductTypeConfig,
} from '@/config/memberShop'
import { noEmptyRules, promisePeriodDaysRules } from '@/validation'
import {
  FindMemberShopPaymentConfig,
  CreateMemberShopPaymentConfig,
  UpdateMemberShopPaymentConfig,
} from '@/api/memberShop'
import formUtils from '@/utils/form'
import { get, omit } from 'lodash'
import store from '@/store'

export default defineComponent({
  name: 'MemberShopPaymentConfigEditModal',
  components: { BaseDialog },
  props: {
    paymentId: { type: String, default: '' },
  },
  emits: ['close', 'refresh'],
  setup (props, { emit }) {
    const shopId = computed(() => store.getters.shop)
    const modalTitle = computed(() => {
      if (props.paymentId) return '編輯付款方式'
      return '新增付款方式'
    })
    const loading = ref(false)
    const formRef = ref(null)
    const formData = reactive({
      paymentType: null,
      limitProductType: null,
      paymentName: null,
      enabled: false,

      mid: null,
      tid: null,
      sid: null,
      promise: false,
      promisePeriodDays: 365,
      promiseVendor: null,
      channelId: null,

      // 藍新
      newebpayMerchantId: null,
      newebpayHashIV: null,
      newebpayHashKey: null,

      // 街口
      jkosStoreId: null,
      jkosApiKey: null,
      jkosSercertKey: null,

      // LINE Pay
      linePayChannelId: null,
      linePayChannelSecret: null,

      // 支付連
      pcHomePayAppID: null,
      pcHomePaySecret: null,
    })
    const formRules = computed(() => {
      const rules = {
        paymentType: [noEmptyRules()],
        paymentName: [noEmptyRules()],
        enabled: [noEmptyRules()],
      }
      if (formData.paymentType === 'sinoPacEpos') {
        rules.mid = [noEmptyRules()]
        rules.tid = [noEmptyRules()]
        rules.sid = [noEmptyRules()]
        rules.promise = [noEmptyRules()]
        rules.promisePeriodDays = [noEmptyRules(), promisePeriodDaysRules(365, 540)]
        rules.promiseVendor = [noEmptyRules()]
        rules.limitProductType = [noEmptyRules()]
      }
      if (formData.paymentType === 'newebpay') {
        rules.newebpayMerchantId = [noEmptyRules()]
        rules.newebpayHashIV = [noEmptyRules()]
        rules.newebpayHashKey = [noEmptyRules()]
      }
      if (formData.paymentType === 'jkos') {
        rules.jkosStoreId = [noEmptyRules()]
        rules.jkosApiKey = [noEmptyRules()]
        rules.jkosSercertKey = [noEmptyRules()]
      }
      if (formData.paymentType === 'linepay') {
        rules.linePayChannelId = [noEmptyRules()]
        rules.linePayChannelSecret = [noEmptyRules()]
      }
      if (formData.paymentType === 'pchomepay') {
        rules.pcHomePayAppID = [noEmptyRules()]
        rules.pcHomePaySecret = [noEmptyRules()]
      }
      if (formData.promise) {
        rules.promiseType = [noEmptyRules()]
        rules.channelId = [noEmptyRules()]
      }
      return rules
    })
    const displayPaymentLimitProductTypeOptions = computed(() => {
      return omit(paymentlimitProductTypeConfig, ['wallet'])
    })
    const displayPaymentTypeOptions = computed(() => {
      return omit(orderPaymentTypeConfig, ['wallet', 'free'])
    })
    const compactPostData = computed(() => {
      const {
        paymentName,
        paymentType, enabled,
        promise, promiseVendor,
        channelId,
        limitProductType, mid, tid, sid, newebpayHashIV,
        newebpayHashKey,
        newebpayMerchantId,
        jkosApiKey,
        jkosSercertKey,
        jkosStoreId,
        pcHomePayAppID,
        pcHomePaySecret,
        linePayChannelId,
        linePayChannelSecret,
        promisePeriodDays,
      } = formData
      const promiseConfig = channelId ? { channelId } : undefined

      let paymentConfig
      if (paymentType === 'sinoPacEpos') {
        paymentConfig = {
          sinoPacBankMerchantId: mid,
          sinoPacBankTerminalId: tid,
          sinoPacBankSecurityId: sid,
        }
      }
      if (paymentType === 'newebpay') {
        paymentConfig = {
          newebpayMerchantId,
          newebpayHashIV,
          newebpayHashKey,
        }
      }
      if (paymentType === 'jkos') {
        paymentConfig = {
          jkosApiKey,
          jkosSercertKey,
          jkosStoreId,
        }
      }
      if (paymentType === 'pchomepay') {
        paymentConfig = {
          pcHomePayAppID,
          pcHomePaySecret,
        }
      }
      if (paymentType === 'linepay') {
        paymentConfig = {
          linePayChannelId,
          linePayChannelSecret,
        }
      }

      return {
        name: paymentName,
        enable: enabled,
        limitProductType: limitProductType || null,
        type: paymentType,
        isPaymentPromise: promise,
        paymentPromiseVendor: promiseVendor || undefined,
        paymentConfig,
        promiseConfig,
        promisePeriodDays,
      }
    })
    const disabledField = computed(() => props.paymentId)
    const findPayment = async () => {
      const [res, err] = await FindMemberShopPaymentConfig({
        shopId: shopId.value,
        id: props.paymentId,
      })
      if (err) return window.$message.error(err)
      return res
    }
    const createPayment = async () => {
      const [, err] = await CreateMemberShopPaymentConfig({
        shopId: shopId.value,
        ...compactPostData.value,
      })
      if (err) return window.$message.error(err)
      window.$message.success('新增成功！')
      emit('refresh')
      emit('close')
    }
    const updatePayment = async () => {
      const [, err] = await UpdateMemberShopPaymentConfig({
        shopId: shopId.value,
        id: props.paymentId,
        ...compactPostData.value,
      })
      if (err) return window.$message.error(err)
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const syncData = (res) => {
      formData.paymentName = res.name
      formData.paymentType = res.type
      formData.limitProductType = res.limitProductType
      formData.enabled = res.enable
      formData.promise = res.isPaymentPromise
      formData.promiseVendor = get(res, 'PaymentPromiseConfig.paymentPromiseVendor')
      formData.promisePeriodDays = get(res, 'PaymentPromiseConfig.promisePeriodDays')
      formData.channelId = get(res, 'promiseConfig.channelId')
      formData.mid = get(res, 'paymentConfig.sinoPacBankMerchantId')
      formData.tid = get(res, 'paymentConfig.sinoPacBankTerminalId')
      formData.sid = get(res, 'paymentConfig.sinoPacBankSecurityId')

      formData.newebpayMerchantId = get(res, 'paymentConfig.newebpayMerchantId')
      formData.newebpayHashIV = get(res, 'paymentConfig.newebpayHashIV')
      formData.newebpayHashKey = get(res, 'paymentConfig.newebpayHashKey')

      formData.pcHomePayAppID = get(res, 'paymentConfig.pcHomePayAppID')
      formData.pcHomePaySecret = get(res, 'paymentConfig.pcHomePaySecret')

      formData.linePayChannelId = get(res, 'paymentConfig.linePayChannelId')
      formData.linePayChannelSecret = get(res, 'paymentConfig.linePayChannelSecret')

      formData.jkosStoreId = get(res, 'paymentConfig.jkosStoreId')
      formData.jkosApiKey = get(res, 'paymentConfig.jkosApiKey')
      formData.jkosSercertKey = get(res, 'paymentConfig.jkosSercertKey')
    }

    const onPaymentTypeChange = (type) => {
      if (props.paymentId) return
      const paymentType = get(orderPaymentTypeConfig, type)
      const defaultName = paymentType.defaultLabel
      if (defaultName) formData.paymentName = defaultName
    }

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const formPass = await formUtils.checkForm(formRef.value)
      if (!formPass) {
        loading.value = false
        return
      }
      if (props.paymentId) await updatePayment()
      else createPayment()
      loading.value = false
    }

    onMounted(async () => {
      loading.value = true
      if (props.paymentId) {
        const res = await findPayment()
        syncData(res)
      }
      loading.value = false
    })

    return {
      get,
      formData,
      formRules,
      orderPaymentTypeConfig,
      paymentPromiseVendorConfig,
      paymentlimitProductTypeConfig,
      displayPaymentLimitProductTypeOptions,
      onConfirm,
      formRef,
      modalTitle,
      loading,
      disabledField,
      displayPaymentTypeOptions,
      onPaymentTypeChange,
    }
  },
})
</script>

<style scoped lang="postcss">

</style>
